.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.table-bordered{
   border: 1px solid gray;
   padding:0px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}
h1,h2{
  padding-left: 35px;
}
input{
  margin-left: 15px !important;
  border-radius:5px;
  padding:5px;
}

/* global-styles for DATATABLE */
/* Customize the header row */
.rdt_TableHeadRow{
  background-color:#090671 !important;
  padding :0px;
  color:#fff;
  font-size:calc(2px + 2vmin); 
}
.rdt_TableHead {
  background-color:#090671;
  padding :0px;
  text-align: center;
}

/* Customize header cells */
.rdt_TableColHeader {
  padding: 10px; 
}

/* Customize table rows */
.rdt_TableRow {
  border-bottom: 1px solid #ccc;   
}

/* Customize cells in table rows */
.rdt_TableCell { 
  font-size: calc(0.5px + 2vmin);  
}
.button {
  background-color: #090671 !important;
}
.button:hover {
  background-color: #ffc107 !important;
  color:#090671 !important;
  border:none;
}
.shadow-lg{
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  width:60%;  
}

.shadow-lg>h1{
  background-color: #090671 !important;
  padding:5px;
  color:#fff;
  margin-top: 0px !important;
  text-align: center;
}
.card-body{
  margin:0 15px;
}

label{
  margin-left: 15px;
  font-size: 20px;
  letter-spacing: 1;
}

.form-control{
  width:97% !important
}
.form-select{
  margin-left: 15px;
  width:97% !important
}
.submit-button{
  margin: 0 auto;
}